import React from "react";
import { Drop, Footer, Seo } from "../components";
import {
	AboutUs,
	CommercialTintService,
	CommercialWindowTintingService,
	Contact,
	Testimonial,
	WhyUs,
} from "../containers";

const CommercialWindowTinting = () => {
	return (
		<div className='w-screen'>
			<Seo
				title='Professional Window Tinting Solutions for Commercial Buildings'
				description='Get the highest-quality window tinting for your commercial property. Our experienced team provides professional installation and expert advice to help you find the perfect solution for your building. Enjoy improved energy efficiency, protection from UV rays, and enhanced privacy with our commercial window'
			/>
			<CommercialWindowTintingService />
			<Testimonial />
			<WhyUs />
			<CommercialTintService />
			<Contact />
			<AboutUs />
			<Drop />
			<Footer />
		</div>
	);
};

export default CommercialWindowTinting;
